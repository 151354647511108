import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TileMenu from "../components/TileMenu"
import styled from "styled-components"
import HeroImage from "../components/HeroImage"
import NextEvents from "../components/NextEvents"
import PodcastLastEpisodes from "../components/PodcastLastEpisodes"

const StyledIndex = styled.div`
  background-color: white;
  /* margin-top: -7rem !important; */
  /* padding: 1em;
  box-shadow: 0 0px 30px -10px rgba(0, 0, 0, 0.3);
  animation: top ease-in-out 1s forwards; */

  /* @keyframes top {
    0% {
      transform: translateY(0rem);
    }
    100% {
      transform: translateY(-7rem);
    }
  } */

  .videoContainer {
    margin-block: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .video {
    flex: 1;

    video {
      width: 100%;
      height: 50vh;
    }
  }

  .text {
    flex: 1;
    text-align: center;

    p {
      font-size: 1.5rem;
    }
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroImage />
    <StyledIndex className="container mt-5">
      <h2
        className="text-center text-uppercase"
        style={{ marginBottom: "2rem" }}
      >
        <strong>
          40 Jahre Erfahrung in Spritzgießtechnik – Wir sind die Experten
        </strong>
      </h2>
      <div className="videoContainer">
        <div className="video">
          <video src="/simba.mp4" autoPlay muted controls loop />
        </div>
        <div className="text">
          <h2>Höchster Alarm!</h2>
          <p>
            Der Fachkräftemangel ist abwendbar durch Ausbildung und Schulungen
            bei uns!
          </p>
        </div>
      </div>
      <TileMenu />
      <PodcastLastEpisodes />
      {/* <NextEvents /> */}
    </StyledIndex>
  </Layout>
)

export default IndexPage
